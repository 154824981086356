/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey="6Lc5Dv8UAAAAAE5i53nY4BDSN0hjOIVzdntLSzQX">
            {element}
        </GoogleReCaptchaProvider>
    )
}
const $ = require("jquery")

export const onInitialClientRender = () => {
  $(document).ready(function () {
    console.log("The answer is don't think about it!")
  });
}