// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-tsx": () => import("./../../../src/pages/acerca-de.tsx" /* webpackChunkName: "component---src-pages-acerca-de-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-equipo-tsx": () => import("./../../../src/pages/equipo.tsx" /* webpackChunkName: "component---src-pages-equipo-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publicaciones-lists-1-tsx": () => import("./../../../src/pages/publicacionesLists1.tsx" /* webpackChunkName: "component---src-pages-publicaciones-lists-1-tsx" */),
  "component---src-pages-publicaciones-lists-2-tsx": () => import("./../../../src/pages/publicacionesLists2.tsx" /* webpackChunkName: "component---src-pages-publicaciones-lists-2-tsx" */),
  "component---src-pages-publicaciones-lists-3-tsx": () => import("./../../../src/pages/publicacionesLists3.tsx" /* webpackChunkName: "component---src-pages-publicaciones-lists-3-tsx" */),
  "component---src-pages-publicaciones-lists-4-tsx": () => import("./../../../src/pages/publicacionesLists4.tsx" /* webpackChunkName: "component---src-pages-publicaciones-lists-4-tsx" */),
  "component---src-pages-publicaciones-tsx": () => import("./../../../src/pages/publicaciones.tsx" /* webpackChunkName: "component---src-pages-publicaciones-tsx" */),
  "component---src-pages-sponsored-research-tsx": () => import("./../../../src/pages/sponsored-research.tsx" /* webpackChunkName: "component---src-pages-sponsored-research-tsx" */)
}

